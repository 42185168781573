import { Section as ContenApiPageSection, Block, Article } from '@root/modules/feed/types/pageContent';
import { Section as FeedApiPageSection, Block as FeedApiPageBlock } from '@root/modules/frontpage/types/frontpage';
import { convertArrayToObject } from '@root/common/utils/helpers';

type SingleSection = FeedApiPageSection[0];
export class FeedAdapter {
  private _pageContent: ContenApiPageSection[];

  constructor(originalData: ContenApiPageSection[]) {
    this._pageContent = originalData;
  }

  private adaptSection(sectionContent: ContenApiPageSection) {
    const { attributes, blocks, id } = sectionContent;

    const section = {} as SingleSection;
    const mergedAttributes = convertArrayToObject<SingleSection['attributes']['settings']>(attributes);

    section.attributes = {
      settings: mergedAttributes,
    };
    section.blocks = [];

    for (const block of blocks.items) {
      const adaptedBlock = this.adaptBlock(block);
      section.blocks.push(adaptedBlock);
    }

    section.id = id;

    return section;
  }

  private adaptBlock(block: Block) {
    const mergedBlockAttributes = Array.isArray(block.attributes) ? convertArrayToObject<Block['attributes']>(block.attributes) : block.attributes;

    const { blockData, ...settings }: { blockData?: Record<string, unknown>; [k: string]: unknown } = mergedBlockAttributes;
    const attributes: FeedApiPageBlock['attributes'] = {};

    if (settings) {
      attributes.settings = settings;
    }

    if (blockData) {
      attributes.data = blockData;
    }

    const blockContent: FeedApiPageBlock = {
      id: block.id,
      type: block.type,
      attributes,
      data: {
        items: this.blockArticles(block.blockArticles.items),
      },
    };

    return blockContent;
  }

  private blockArticles(items: Block['blockArticles']['items']) {
    const articles: Article[] = [];

    for (const item of items) {
      if (!item.article) {
        continue;
      }

      const article = { ...item.article };

      // Overwrite manually changed article attributes in page builder admin
      article.content.title = item.title || article.content.title;
      article.content.lead = item.lead || article.content.lead;
      article.content.subtitle = item.subtitle || article.content.subtitle;
      article.metaImage = item.metaImage || article.metaImage;

      if (item.relatedArticles?.length) {
        const relatedArticles = item.relatedArticles.map((item) => item.article);
        article.relatedArticles = {
          items: [],
        };
        article.relatedArticles.items = [...relatedArticles];
      }

      // Merge attributes (queried as non-boolean) with settings (queried as boolean)
      if (article.attributes?.length) {
        article.settings = [...article.settings, ...article.attributes];
      }

      // Export pin status
      article.pin = item.pin;

      delete article.attributes;

      articles.push(article);
    }

    return articles;
  }

  public adapt() {
    const feed: FeedApiPageSection = [];

    for (const section of this._pageContent) {
      const adaptedSection = this.adaptSection(section);
      feed.push(adaptedSection);
    }

    return feed;
  }
}
